import { Outlet, useLocation, useParams } from "react-router-dom";
import { PageHeader } from "@/components/layout/page-header";

import { SidebarNav } from "@/components/layout/side-nav";
import { Separator } from "@/components/ui/separator";
import { Breadcrumb } from "@/components/layout/breadcrumb";
import { useEffect, useState } from "react";
import {
  Application,
  useApplicationService,
} from "@/services/application-service";
import { LoadingPage } from "../loading";

interface ApplicationSideBarProps {
  id: string;
}

function ApplicationSideBar({ id }: ApplicationSideBarProps) {
  const sidebarNavItems = [
    {
      title: "Overview",
      href: `/application/${id}/overview`,
    },
    {
      title: "Settings",
      href: `/application/${id}/settings`,
    },
    {
      title: "Logs",
      href: `/application/${id}/logs`,
    },
  ];
  return <SidebarNav items={sidebarNavItems} />;
}

function ApplicationDetailPage() {
  const { id } = useParams();

  const { pathname } = useLocation();
  const paths = pathname.split("/").filter((path) => path !== "");

  const [application, setApplication] = useState<Application>();
  const [isLoading, setIsLoading] = useState(true);
  const { applicationService } = useApplicationService();

  useEffect(() => {
    applicationService.get(id as string).then((data) => {
      setApplication(data);
      setIsLoading(false);
    });
  }, [id]);
  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Breadcrumb root={paths[0]} current={paths[2]} />
      <PageHeader
        title={application?.displayName as string}
        subtitle={application?.type as string}
      />
      <Separator className="my-6" />

      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/6">
          <ApplicationSideBar id={id as string} />
        </aside>
        <div className="flex-1 lg:max-w-full">
          <Outlet context={application} />
        </div>
      </div>
    </>
  );
}

export { ApplicationDetailPage };
