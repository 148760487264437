import HomePage from "./pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppLayout } from "@/components/layout/app-layout";

import keycloak from "@/auth/auth";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ProjectIndexPage } from "@/pages/project/project-index";
import { ApplicationIndexPage } from "@/pages/application/application-index";
import { ApplicationCreatePage } from "@/pages/application/application-create";
import { ApplicationDetailPage } from "@/pages/application/application-detail";
import { ApplicationSettingsPage } from "@/pages/application/application-detail/application-detail-settings";
import { ApplicationOverviewPage } from "@/pages/application/application-detail/application-detail-overview";
import { AccountSetupPage } from "@/pages/account/account-setup";
import { NotFoundPage } from "@/pages/errors/not-found";
import { ProjectOverviewPage } from "@/pages/project/project-overview";
import { ProjectInfrastructurePage } from "@/pages/project/project-infrastructure";
import { ClusterCreatePage } from "@/pages/cluster/cluster-create";
import { GuestLayout } from "@/components/layout/guest-layout";
import { SignupPage } from "@/pages/signup";
import { SignupConfirmationPage } from "@/pages/signup-confirmation";
import { ClusterDetailPage } from "./pages/cluster/cluster-detail";

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/application" element={<ApplicationIndexPage />} />
            <Route
              path="/application/create"
              element={<ApplicationCreatePage />}
            />
            <Route path="/application/:id" element={<ApplicationDetailPage />}>
              <Route
                path="/application/:id/overview"
                element={<ApplicationOverviewPage />}
              />
              <Route
                path="/application/:id/settings"
                element={<ApplicationSettingsPage />}
              />
              <Route
                path="/application/:id/logs"
                element={<ApplicationSettingsPage />}
              />
            </Route>
            <Route path="/project" element={<ProjectIndexPage />}>
              <Route
                path="/project/overview"
                element={<ProjectOverviewPage />}
              />
              <Route
                path="/project/infrastructure"
                element={<ProjectInfrastructurePage />}
              />
              <Route
                path="/project/cluster-create"
                element={<ClusterCreatePage />}
              />
              <Route
                path="/project/cluster/:id/detail"
                element={<ClusterDetailPage />}
              />
              <Route path="/project/*" element={<ProjectOverviewPage />} />
            </Route>
          </Route>
          <Route path="/account/initial-setup" element={<AccountSetupPage />} />

          <Route element={<GuestLayout />}>
            <Route path="/signup" element={<SignupPage />} />
            <Route
              path="signup-confirmation"
              element={<SignupConfirmationPage />}
            />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

export default App;
