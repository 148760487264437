import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  root: string;
  current: string;
}

function Breadcrumb({ root, current }: BreadcrumbProps) {
  return (
    <div className="mb-4 flex items-center space-x-1 text-sm text-muted-foreground">
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
        <Link to={`/${root}`}>
          {root.charAt(0).toUpperCase() + root.slice(1)}
        </Link>
      </div>
      <ChevronRightIcon className="h-4 w-4" />
      <div className="font-medium text-foreground">
        {current.charAt(0).toUpperCase() + current.slice(1)}
      </div>
    </div>
  );
}

export { Breadcrumb };
