import { Button } from "../ui/button";

function CloudAccountConnection() {
  return (
    <p className="text-muted-foreground">
      Connected to Azure.
      <Button variant="link" className="mx-2 px-0">
        Update credentials
      </Button>
    </p>
  );
}

export { CloudAccountConnection };
