import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Share1Icon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { Loader2 } from "lucide-react";
import { useOrganizationInviteService } from "@/services/organization-invite-service";

function OrganizationInvite() {
  const [isLoading, setIsLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { organizationInviteService } = useOrganizationInviteService();

  const handleEmailChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => {
    setEmailValue(value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await organizationInviteService.create({ userEmailAddress: emailValue });
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="outline">
          <Share1Icon className="mr-2 h-4 w-4" /> Invite to organization
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Invite new users</SheetTitle>
          <SheetDescription>
            Please provide a valid email to invite a new user. It can be a new
            account or external (from other organizations).
          </SheetDescription>
        </SheetHeader>
        <div className="space-y-4 mt-4">
          <Input value={emailValue} onChange={handleEmailChange} />
          <Button loading={isLoading} onClick={() => handleSubmit()}>
            Invite
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export { OrganizationInvite };
