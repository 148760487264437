import { useAxios } from "@/services/axios";
import { Organization } from "@/services/organization-service";

export type OrganizationInviteRequest = {
  userEmailAddress: string;
};

export type OrganizationInvite = {
  userEmailAddress: string;
  expiresOn: Date;
  createdAt: Date;
  organization: Organization;
  id: string;
};

const useOrganizationInviteService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/accounts/organization-invite";

  const organizationInviteService = {
    create: async (request: OrganizationInviteRequest) => {
      try {
        const response = await axiosInstance.post(baseServiceUri, request);
        return response.data;
      } catch (error) {
        console.error("Error creating the organization invite:", error);
        throw error;
      }
    },

    get: async () => {
      try {
        const response = await axiosInstance.get<OrganizationInvite>(
          baseServiceUri
        );
        return response.data;
      } catch (error) {
        console.error("Error creating the organization invite:", error);
        throw error;
      }
    },

    accept: async (id: string) => {
      try {
        const uri = `${baseServiceUri}/${id}/accept`;
        await axiosInstance.patch(uri);
        
      } catch (error) {
        console.error("Error creating the organization invite:", error);
        throw error;
      }
    },
    reject: async (id: string) => {
      try {
        const uri = `${baseServiceUri}/${id}/reject`;
        await axiosInstance.patch(uri);
        
      } catch (error) {
        console.error("Error creating the organization invite:", error);
        throw error;
      }
    },
  };
  return { organizationInviteService };
};

export { useOrganizationInviteService };
