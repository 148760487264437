import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useCloudAccountService } from "@/services/cloud-account-service";
import { useCurrentProject } from "@/contexts/current-project-context";

interface CloudAccountCreateAzureProps {
  onClose: () => void;
}
function CloudAccountCreateAzure({ onClose }: CloudAccountCreateAzureProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { currentProject } = useCurrentProject();
  const { cloudAccountService } = useCloudAccountService();

  const formSchema = z.object({
    clientId: z.string().min(2, {
      message: "displayname must be at least 2 characters.",
    }),
    clientSecret: z.string(),
    tenantId: z.string(),
    subscriptionId: z.string(),
  });

  type formValues = z.infer<typeof formSchema>;

  const defaultValues: Partial<formValues> = {};

  const projectId = currentProject ? currentProject?.id : "";

  const form = useForm<formValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const onSubmit = async (data: formValues) => {
    setIsLoading(true);
    await cloudAccountService.create({
      projectId: projectId as string,
      organizationId: currentProject?.organizationId as string,
      ...data,
    });
    setIsLoading(false);
    onClose();
  };

  return (
    <Form {...form}>
      <div className="space-y-8">
        <div className="grid grid-rows-1 gap-4">
          <p className="text-mutted-foreground">Connect to: Azure</p>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="clientId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>ClientId</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Client ID from service principal"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="clientSecret"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Client secret</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Client Secret from service principal"
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tenantId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>TenantId</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Tenant ID from your tenant"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subscriptionId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SubscriptionId</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Subscription ID to host your resources"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-full space-x-2 pt-8 flex justify-end">
        <Button loading={isLoading} onClick={form.handleSubmit(onSubmit)}>
          Create
        </Button>
      </div>
    </Form>
  );
}

export { CloudAccountCreateAzure };
