import Loading from "@/components/ui/loading";


function LoadingPage() {

    return <div className="flex flex-row h-screen w-screen items-center justify-center">
        <Loading />
    </div>
}

export { LoadingPage }