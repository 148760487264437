import { MainNav } from "@/components/layout/main-nav";
import { UserNav } from "@/components/layout/user-nav";
import { AppsNav } from "@/components/layout/apps-nav";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { LoadingPage } from "@/pages/loading";
import { Toaster } from "@/components/ui/toaster";
import { useEffect, useState } from "react";

import {
  CurrentProjectContext,
  Project,
} from "@/contexts/current-project-context";

import ProjectSwitcher from "@/components/layout/project-switcher";

function AppLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const [currentProject, setCurrentProject] = useState<Project | null>(null);

  const hasUserOrganizations =
    keycloak.tokenParsed?.organizations &&
    keycloak.tokenParsed?.organizations.length > 0;

  useEffect(() => {
    if (isLoggedIn && initialized) {
      if (!hasUserOrganizations) {
        console.info("User doesn't belong to an organization");
        navigate("/account/initial-setup");
      }
    }
  }, [pathname, keycloak, initialized, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && initialized) {
      keycloak.login();
    }
  }, [keycloak, initialized, isLoggedIn]);

  if (!initialized) {
    return <LoadingPage />;
  }

  const providerValue = {
    currentProject: currentProject,
    updateCurrentProject: setCurrentProject,
  };

  return isLoggedIn && hasUserOrganizations ? (
    <>
      <CurrentProjectContext.Provider value={providerValue}>
        <div className="flex-col md:flex">
          <div className="flex h-16 items-center px-2 md:px-8">
            <MainNav />
            <div className="ml-auto flex items-center space-x-4">
              {hasUserOrganizations && <ProjectSwitcher />}
              <UserNav />
              {/* <AppsNav /> */}
            </div>
          </div>
          <div className="flex-1 space-y-4 p-8 pt-6">
            {currentProject && <Outlet />}
            <Toaster />
          </div>
        </div>
      </CurrentProjectContext.Provider>
    </>
  ) : (
    <p>Access denied. Redirecting to login page.</p>
  );
}

export { AppLayout };
