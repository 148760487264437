import { PageHeader } from "@/components/layout/page-header";
import { SidebarNav } from "@/components/layout/side-nav";
import { Separator } from "@/components/ui/separator";
import { Outlet } from "react-router-dom";

function ProjectIndexPage() {

  const sidebarNavItems = [
    {
      title: "Overview",
      href: `/project/overview`,
    },
    {
      title: "Infrastructure",
      href: `/project/infrastructure`,
    },
    {
      title: "Notification Channels",
      href: `/project/notification-channels`,
    },
    {
      title: "Servicing Config",
      href: `/project/servicing-config`,
    },
  ];

  return (
    <>
      <PageHeader
        title="Project Settings"
        subtitle="Adjust your project details"
      ></PageHeader>
      <Separator className="my-6" />

      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/6">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 lg:max-w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export { ProjectIndexPage };
