import { Outlet } from "react-router-dom";

function GuestLayout() {
  return (
    <div className="flex-col md:flex">
      <Outlet />
    </div>
  );
}

export { GuestLayout };
