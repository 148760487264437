import { useCurrentProject } from "@/contexts/current-project-context";

function ProjectOverviewPage() {
  const { currentProject } = useCurrentProject();

  return (
    <div className="flex flex-col">
      <h1 className="font-semibold text-lg">Overview</h1>
      <div className="grid grid-cols-3 mt-4">
        <div className="flex flex-col">
          <span className="font-medium">Project</span>
          <span className="font-normal leading-snug text-muted-foreground">
            {currentProject?.displayName as string}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="font-medium">Project ID</span>
          <span className="font-normal leading-snug text-muted-foreground">
            {currentProject?.id as string}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="font-medium">Organization ID</span>
          <span className="font-normal leading-snug text-muted-foreground">
            {currentProject?.organizationId as string}
          </span>
        </div>
      </div>
    </div>
  );
}

export { ProjectOverviewPage };
