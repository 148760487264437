import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function ApplicationStatsMemory() {
  const data = [
    {
      timeStamp: 0,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 1,
      memoryUsageMb: 0.55,
    },
    {
      timeStamp: 2,
      memoryUsageMb: 0.6,
    },
    {
      timeStamp: 3,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 4,
      memoryUsageMb: 0.3,
    },
    {
      timeStamp: 5,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 6,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 7,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 8,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 9,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 10,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 11,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 12,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 13,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 14,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 15,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 16,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 17,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 18,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 19,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 20,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 21,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 22,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 23,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 24,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 25,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 26,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 27,
      memoryUsageMb: 0.5,
    },
    {
      timeStamp: 28,
      memoryUsageMb: 0.47,
    },
    {
      timeStamp: 29,
      memoryUsageMb: 0.4,
    },
    {
      timeStamp: 30,
      memoryUsageMb: 0.5,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={100}>
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: -20,
          bottom: 0,
        }}
        syncId="appStats"
      >
        <XAxis dataKey="timeStamp" hide />
        <CartesianGrid stroke="#cecece" strokeDasharray="1 1" />

        <YAxis
          domain={[0, 1]}
          color="#cecece"
          stroke="#ccc"
          fontSize={8}
          tickCount={10}
          tickMargin={0}
        />

        <Tooltip />

        <Bar dataKey="memoryUsageMb" fill="#0ea5e9" stroke="#0ea5e9" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export { ApplicationStatsMemory };
