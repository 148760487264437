import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

function NavItem(props: { label: string; href: string }) {
  return (
    <Link
      to={props.href}
      className={cn(
        "text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
      )}
    >
      {props.label}
    </Link>
  );
}

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const linkList = [
    { label: "Overview", href: "/" },
    { label: "Apps", href: "/application" },
    { label: "Databases", href: "/databases" },
    { label: "Settings", href: "/project/overview" },
  ];

  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      <Link to="/">
        <div className="flex flex-row items-center justify-center space-x-1">
          <strong>Kura</strong>
        </div>
      </Link>
      {linkList.map((item, index) => (
        <NavItem key={index} label={item.label} href={item.href} />
      ))}
    </nav>
  );
}
