import { CloudAccountCreate } from "@/components/cloud-account/cloud-account-create";
import { ClusterList } from "@/components/cluster/cluster-list";
import { useCurrentProject } from "@/contexts/current-project-context";
import { useCloudAccountService } from "@/services/cloud-account-service";
import { useEffect, useState } from "react";
import Loading from "@/components/ui/loading";
import { CloudAccountConnection } from "@/components/cloud-account/cloud-account-connection";
import { Cluster, useClusterService } from "@/services/cluster-service";

function ProjectInfrastructurePage() {
  const { cloudAccountService } = useCloudAccountService();
  const { clusterService } = useClusterService();
  const { currentProject } = useCurrentProject();

  const [hasCloudProvider, setHasCloudProvider] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isClusterLoading, setClusterIsLoading] = useState(true);
  const [clusterList, setClusterList] = useState<Cluster[]>([]);

  useEffect(() => {
    fetchData();
  }, [currentProject]);

  const fetchData = () => {
    setIsLoading(true);
    cloudAccountService
      .get()
      .then(() => {
        fetchClusters();
      })
      .catch(() => {
        setHasCloudProvider(false);
        setIsLoading(false);
      });
  };

  const fetchClusters = () => {
    setClusterIsLoading(true);
    clusterService.list().then((clusterList) => {
      console.log(clusterList);
      setClusterList(clusterList as Cluster[]);
      setHasCloudProvider(true);
      setClusterIsLoading(false);
      setIsLoading(false);

    });
  };

  const handleOnClose = () => {
    fetchData();
  };

  if (isLoading) {
    return (
      <div className="h-96 w-full flex flex-row items-center justify-center">
        <Loading />
      </div>
    );
  }

  return hasCloudProvider ? (
    <>
      <CloudAccountConnection />
      {!isClusterLoading ? (
        <ClusterList items={clusterList} onRefresh={() => fetchClusters()} />
      ) : (
        <div className="h-96 w-full flex flex-row items-center justify-center">
          <Loading />
        </div>
      )}
    </>
  ) : (
    <div className="flex flex-col items-center justify-center h-[400px] w-[800px]">
      <p className="text-muted-foreground text-lg my-2">
        Looks like you don't have a connection to your cloud provider...
      </p>
      <CloudAccountCreate onClose={handleOnClose} />
    </div>
  );
}

export { ProjectInfrastructurePage };
