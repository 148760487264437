import { useAxios } from "@/services/axios";

export type SignupLocalAccountRequest = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  companyName: string;
};

const useSignupService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/accounts/signup";

  const signupService = {
    signupLocalAccount: async (request: SignupLocalAccountRequest) => {
      try {
        const response = await axiosInstance.post(baseServiceUri, request);
        return response.data;
      } catch (error) {
        console.error("Error creating the account:", error);
        throw error;
      }
    },
  };
  return { signupService };
};

export { useSignupService };
