import { Separator } from "@/components/ui/separator";

function PageHeader(props: {
  title: string;
  subtitle?: string;
  children?: string | JSX.Element | JSX.Element[];
}) {
  return (
    <div className="flex items-center justify-between space-y-2">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">{props.title}</h2>
        <p className="text-muted-foreground">{props.subtitle}</p>
      </div>
      <div className="flex items-center space-x-2">{props.children}</div>
    </div>
  );
}

export { PageHeader };
