import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LayersIcon } from "lucide-react";

function AppsNav() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="IconButton">
          <LayersIcon />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuItem>Status Pages</DropdownMenuItem>
        <DropdownMenuItem>Infrastructure</DropdownMenuItem>
        <DropdownMenuItem>Developer Platform</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { AppsNav };
