import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge, BadgeProps } from "@/components/ui/badge";
import { CubeIcon, ExternalLinkIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { Application } from "@/services/application-service";
import Moment from "react-moment";

enum ApplicationStatus {
  running = "running",
  failed = "failed",
  upgrading = "upgrading",
}

function ApplicationStatusBadge(props: { status: ApplicationStatus }) {
  let badgeVariant: BadgeProps = { variant: "default" };

  switch (props.status) {
    case ApplicationStatus.failed:
      badgeVariant.variant = "destructive";
      break;
    case ApplicationStatus.upgrading:
      badgeVariant.variant = "outline";
      break;
    default:
      badgeVariant.variant = "default";
  }
  return <Badge variant={badgeVariant.variant}>{props.status}</Badge>;
}

interface ApplicationTileProps {
  status: ApplicationStatus;
  application: Application;
}

function ApplicationTile({ application, status }: ApplicationTileProps) {
  const { type, displayName, id, createdAt } = application;

  return (
    <Card className="hover:bg-accent hover:text-accent-foreground ">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{type}</CardTitle>
        <div className="flex flex-row items-center space-x-2">
          <ApplicationStatusBadge status={status} />
          <a target="_blank" href={application.url} rel="noreferrer">
            <ExternalLinkIcon />
          </a>
        </div>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">
          <Link to={`/application/${id}/overview`}>{displayName}</Link>
        </div>
        <p className="text-xs text-muted-foreground">98cc3f</p>
        <p className="text-xs text-muted-foreground pt-2">
          Last deployment: <Moment fromNow>{createdAt}</Moment>
        </p>
      </CardContent>
    </Card>
  );
}

export { ApplicationStatus, ApplicationTile };
