import { useCurrentProject } from "@/contexts/current-project-context";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

export const useAxios = () => {
  const { currentProject } = useCurrentProject();
  const { keycloak, initialized } = useKeycloak();

  const axiosBase = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your actual backend URL
    headers: {
      common: {
        Authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
        "Content-Type": "application/json",
        "x-kxp-organization-id": currentProject?.organizationId as string,
        "x-kxp-project-id": currentProject?.id as string,
      },
    },
  });

  return axiosBase;
};
