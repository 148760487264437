import { toast } from "@/components/ui/use-toast";
import { useAxios } from "@/services/axios";

interface CreateApplicationRequest {
  displayName: string;
  regionId: string;
  repoUrl: string;
  shortName: string;
  type: string;
  initialContainerImage: string;
}

export type Application = {
  id: string;
  displayName: string;
  status: string
  type: string
  url: string
  createdAt: Date
};

const useApplicationService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/applications/application";

  const applicationService = {
    list: async () => {
      try {
        const { data } = await axiosInstance.get<Application[]>(baseServiceUri);
        return data;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error processing a request",
          description: `We couldn't fetch your applications. Error message: ${error}`,
        });
      }
    },
    getSummary: async (projectId: string) => {
      try {
        const { data } = await axiosInstance.get(
          `${baseServiceUri}/summary?project_id=${projectId}`
        );
        return data;
      } catch (error) {
        console.error("Error fetching application:", error);
        throw error;
      }
    },
    get: async (applicationId: string) => {
      try {
        const { data } = await axiosInstance.get(
          `${baseServiceUri}/${applicationId}`
        );
        return data;
      } catch (error) {
        console.error("Error fetching application:", error);
        throw error;
      }
    },

    create: async (request: CreateApplicationRequest) => {
      try {
        console.log(request);
        const response = await axiosInstance.post(baseServiceUri, request);

        toast({
          title: "Success! Your application has been created",
          description: "Your application has been created and will be available in a few seconds."
        });

        return response.data;

      } catch (error) {
        console.error("Error creating application:", error);
        throw error;
      }
    },
  };
  return { applicationService };
};

export { useApplicationService };
