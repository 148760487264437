import Keycloak, { KeycloakConfig } from "keycloak-js";

const config: KeycloakConfig = {
  url: "https://sso.kura.cloud/",
  realm: "kops-production",
  clientId: "k-operations-customer-app",
  
};

const keycloak = new Keycloak(config);

export default keycloak;
