import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import { InfoCircledIcon } from "@radix-ui/react-icons";

function ApplicationLogs() {
  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Timestamp</TableHead>
            <TableHead className="w-[100px]">Level</TableHead>
            <TableHead>Message</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:01</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:05</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:05</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:05</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:05</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">2023-07-03 09:53:05</TableCell>
            <TableCell className="text-center items-center justify-center"><InfoCircledIcon className="text-sky-600"/></TableCell>
            <TableCell>[19:14:27 INF] Received HTTP response headers after 203.6204ms - 200</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

export { ApplicationLogs };
