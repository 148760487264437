import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";


const CustomTick = (props: any) => {
  const { x, y, payload } = props;
  const value = payload.value;

  // Check if the value is divisible by 100
  const isDivisibleBy100 = value % 100 === 0;

  // Only show the tick label for values divisible by 100
  return isDivisibleBy100 ? (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {value}
      </text>
    </g>
  ) : null;
};

function ApplicationStatsRequests() {
  const data = [
    { timeStamp: 1, responseMs: 200 },
    { timeStamp: 2, responseMs: 120 },
    { timeStamp: 3, responseMs: 130 },
    { timeStamp: 4, responseMs: 110 },
    { timeStamp: 5, responseMs: 117 },
    { timeStamp: 6, responseMs: 127 },
    { timeStamp: 7, responseMs: 127 },
    { timeStamp: 8, responseMs: 127 },
    { timeStamp: 9, responseMs: 127 },
    { timeStamp: 10, responseMs: 127 },
    { timeStamp: 11, responseMs: 327 },
    { timeStamp: 12, responseMs: 321 },
    { timeStamp: 13, responseMs: 127 },
    { timeStamp: 14, responseMs: 127 },
    { timeStamp: 15, responseMs: 127 },
    { timeStamp: 16, responseMs: 127 },
    { timeStamp: 17, responseMs: 127 },
    { timeStamp: 18, responseMs: 127 },
    { timeStamp: 19, responseMs: 127 },
    { timeStamp: 20, responseMs: 127 },
    { timeStamp: 21, responseMs: 127 },
    { timeStamp: 22, responseMs: 127 },
    { timeStamp: 23, responseMs: 127 },
    { timeStamp: 24, responseMs: 127 },
    { timeStamp: 25, responseMs: 127 },
    { timeStamp: 26, responseMs: 127 },
    { timeStamp: 27, responseMs: 127 },
    { timeStamp: 28, responseMs: 127 },
    { timeStamp: 29, responseMs: 127 },
    { timeStamp: 30, responseMs: 127 },
  ];

  return (
    <ResponsiveContainer width="100%" height={100}>
      <ScatterChart
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: -20,
        }}
        
        syncId="appStats"
      >
        <CartesianGrid stroke="#cecece" strokeDasharray="1 1"  />
        <XAxis dataKey="timeStamp" name="timestamp" hide />
        <YAxis
          type="number"
          dataKey="responseMs"
          name="responseMs"
          unit="ms"
          domain={[0, 1000]}
          color="#cecece"
          stroke="#ccc"
          fontSize={8}
          tickCount={10}
          tickMargin={0}
        />
        <Tooltip cursor={{ strokeDasharray: "2 2" }} />
        <Scatter data={data} fill="#0ea5e9" />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export { ApplicationStatsRequests };
