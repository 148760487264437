import { toast } from "@/components/ui/use-toast";
import { useAxios } from "./axios";
import { CloudProviderEnum } from "./enums/cloud-provider";
import { ClusterStatusEnum } from "./enums/cluster-status";

export type Cluster = {
  id: string;
  displayName: string;
  createdAt: Date;
  location: string;
  clusterPreset: string;
  nodeCount: string;
  nodeSize: string;
  provider: CloudProviderEnum;
  status: ClusterStatusEnum;
  defaultDomain: string;
};

export type ClusterPreset = {
  id: string;
  display_name: string;
  description: string;
};

interface CreateClusterRequest {
  displayName: string;
  location: string;
  clusterPreset: string;
  domain: string;
}

const useClusterService = () => {
  const axiosInstance = useAxios();

  const baseServiceUri = "/applications/cluster";
  const clusterService = {
    get: async (id: string) => {
      try {
        const uri = `${baseServiceUri}/${id}`;
        const { data } = await axiosInstance.get<Cluster>(uri);
        return data;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error processing a request",
          description: `We couldn't fetch your cluster. Error message: ${error}`,
        });
      }
    },
    list: async () => {
      try {
        const { data } = await axiosInstance.get<Cluster[]>(baseServiceUri);
        return data;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error processing a request",
          description: `We couldn't fetch your clusters. Error message: ${error}`,
        });
      }
    },
    create: async (request: CreateClusterRequest) => {
      try {
        const { data } = await axiosInstance.post(baseServiceUri, request);
        return data;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error processing a request",
          description: `Cannot create the cluster. Error message: ${error}`,
        });
        throw error;
      }
    },
    updateDefaultDomain: async (clusterId: string, defaultDomain: string) => {
      try {
        const uri = `${baseServiceUri}/${clusterId}/default-domain`;

        const { data } = await axiosInstance.patch(uri, {
          defaultDomain: defaultDomain,
        });

        toast({
          title: "Updated your cluster!",
          description: `We successfully patched your cluster. `,
        });

        return data;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error processing a request",
          description: `Couldn't update the cluster. Error message: ${error}`,
        });
        throw error;
      }
    },
  };

  const clusterPresetService = {
    list: async () => {
      const { data } = await axiosInstance.get<ClusterPreset[]>(
        "/applications/cluster-preset"
      );
      return data;
    },
  };

  return { clusterService, clusterPresetService };
};

export { useClusterService };
