import DashboardTile from "@/components/dashboard/dashboard-tile";
import { PageHeader } from "@/components/layout/page-header";
import { OrganizationInvite } from "@/components/organization/organization-invite";
import { Button } from "@/components/ui/button";
import Grid from "@/components/ui/grid";
import { useCurrentProject } from "@/contexts/current-project-context";
import { useProjectService } from "@/services/project-service";
import { useApplicationService } from "@/services/application-service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ApplicationSummary {
  application_count: number;
}

interface ProjectSummary {
  memberCount: number;
}

export default function HomePage() {
  const navigate = useNavigate();

  const { currentProject } = useCurrentProject();
  const { applicationService } = useApplicationService();
  const { projectService } = useProjectService();

  const [applicationSummary, setApplicationSummary] =
    useState<ApplicationSummary>();

  const [projectSummary, setProjectSummary] = useState<ProjectSummary>();

  const applicationCount = applicationSummary
    ? applicationSummary.application_count.toString()
    : "0";

  const memberCount = projectSummary
    ? projectSummary.memberCount.toString()
    : "0";

  useEffect(() => {
    if (currentProject) {
      applicationService
        .getSummary(currentProject?.id as string)
        .then((response) => setApplicationSummary(response));

      projectService
        .getSummary(currentProject?.id as string)
        .then((response) => setProjectSummary(response));
    }
  }, [currentProject]);

  return (
    <>
      <PageHeader title="Dashboard">
        <OrganizationInvite />
        <Button onClick={() => navigate("/application/create")}>
          Create App
        </Button>
      </PageHeader>

      {/* <Tabs defaultValue="overview" className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="analytics">History</TabsTrigger>
        </TabsList>
      </Tabs> */}

      <Grid>
        <DashboardTile
          title="Running apps"
          subtitle="Applications actually running may differ"
          value={applicationCount}
        />
        <DashboardTile title="Project members" value={memberCount} />
        {/* <DashboardTile title="Active Alerts" value="0" /> */}

        {/* <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Recent Actions</CardTitle>
            <CardDescription>
              Your team made 265 actions this month.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <RecentActions />
          </CardContent>
        </Card> */}
      </Grid>
    </>
  );
}
