import { useAxios } from "@/services/axios";

interface CreateCloudAccountRequest {
  projectId: string;
  organizationId: string;
  clientId: string;
  clientSecret: string;
  tenantId: string;
}

const useCloudAccountService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/applications/cloud-account";

  const cloudAccountService = {
    get: async () => {
      const { data } = await axiosInstance.get(baseServiceUri);
      return data;
    },
    create: async (request: CreateCloudAccountRequest) => {
      try {
        const { data } = await axiosInstance.post(baseServiceUri, request);
        return data;
      } catch (error) {
        console.error("Error creating cloud account:", error);
        throw error;
      }
    },
  };
  return { cloudAccountService };
};

export { useCloudAccountService };
