import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { industryOptions } from "@/data/business-industries";
import { Button } from "../ui/button";
import { useAccountSetupService } from "@/services/account-setup-service";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

function OrganizationCreateForm() {
  const { accountSetupService } = useAccountSetupService();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const formSchema = z.object({
    displayName: z.string().min(2),
    legalName: z.string().min(2),
    businessIndustry: z.string().min(2),
    taxId: z.string().min(2),
    preferredRegion: z.string(),
  });

  type formValues = z.infer<typeof formSchema>;

  const defaultValues: Partial<formValues> = {
    displayName: "",
  };

  const form = useForm<formValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: formValues) => {
    setIsLoading(true);
    await accountSetupService.create({
      organization: { ...data },
    });
    setIsLoading(false);

    keycloak.updateToken(99999).then(() => {
      setIsLoading(false);
      navigate("/");
    });
  };

  return (
    <div className="flex flex-row">
      <Form {...form}>
        <div className="flex-col">
          <div className="space-y-8">
            <p className="mt-4 text-md text-muted-foreground">
              First, We'll need some information about your company. Please
              provide the following info:
            </p>

            <div className="grid grid-cols-4 gap-2">
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Company name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Your company display name."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="businessIndustry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Business industry</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select an industry" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {industryOptions.map((item) => (
                          <SelectItem key={item.value} value={item.label}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-4 gap-2">
              <FormField
                control={form.control}
                name="legalName"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Legal name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Your company legal name."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="taxId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tax ID</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-3 gap-2">
              <FormField
                control={form.control}
                name="preferredRegion"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Preferred region</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a region" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="us-east-1">us-east-1</SelectItem>
                        <SelectItem value="us-east-2">us-east-2</SelectItem>
                        <SelectItem value="br-south-1">br-south-1</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      We use this information by default for new projects and
                      deployments. It can be changed at any time.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="grid grid-cols-4 gap-2">
            <div className="col-start-4 space-x-3">
              <Button
                type="submit"
                variant="outline"
                onClick={() => form.reset()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                onClick={form.handleSubmit(onSubmit)}
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export { OrganizationCreateForm };
