import { ScrollArea } from "@/components/ui/scroll-area";
import { ReactNode } from "react";

interface ContainerRegistryListItemProps {
  item: {
    id: number;
    displayName: string;
  };

  onSelected: () => void;
}

function ContainerRegistryListItem({
  item,
  onSelected,
}: ContainerRegistryListItemProps) {
  return (
    <>
      <li className="text-sm" key={item.id}>
        <a href="#" className="underline" onClick={onSelected}>
          {item.displayName}
        </a>
      </li>
    </>
  );
}

interface ContainerRegistryListRootProps {
  children: ReactNode;
}

function ContainerRegistryListRoot({
  children,
}: ContainerRegistryListRootProps) {
  
  return (
    <ScrollArea className="min-h-full">
      <div className="p-4">
        <h4 className="mb-4 text-sm font-medium leading-none">
          Registry repositories
        </h4>
        <ul>{children}</ul>
      </div>
    </ScrollArea>
  );
}

export const ContainerRegistryList = {
  Root: ContainerRegistryListRoot,
  Item: ContainerRegistryListItem,
};
