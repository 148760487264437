import React from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { SearchIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { ContainerRegistryList } from "@/components/container-registry/container-registry-list";
import { useState } from "react";

interface ContainerRegistryPickerPops {
  onSelected: (displayName: string) => void;
}

function ContainerRegistryPicker({ onSelected }: ContainerRegistryPickerPops) {
  const [isOpen, setIsOpen] = useState(false);

  const list = [
    {
      id: 1,
      displayName: "solucoesmoderna.azurecr.io/purchase-service:1",
    },
    { id: 2, displayName: "solucoesmoderna.azurecr.io/purchase-service:2" },
  ];

  const setSelectedItem = (displayName: string) => {
    onSelected(displayName);
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon">
          <SearchIcon size="12px" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Select a container image</SheetTitle>
          <SheetDescription>
            <div className="flex flex-1 items-center space-x-2">
              <Input
                placeholder="Start typing..."
                className="h-8 w-[150px] lg:w-[250px]"
              />
            </div>
            <div className="mt-4 w-full">
              <ContainerRegistryList.Root>
                {list.map((item, index) => (
                  <ContainerRegistryList.Item
                    item={item}
                    key={index}
                    onSelected={() => setSelectedItem(item.displayName)}
                  />
                ))}
              </ContainerRegistryList.Root>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}

export { ContainerRegistryPicker };
