


function NotFoundPage() {


    return <p>Page not found</p>
}


export { NotFoundPage }