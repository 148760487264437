import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { curveCardinal } from "d3-shape";

function ApplicationStatsCpu() {
  const data = [
    {
      timeStamp: 0,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 1,
      cpuUsagePercent: 0.55,
    },
    {
      timeStamp: 2,
      cpuUsagePercent: 0.6,
    },
    {
      timeStamp: 3,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 4,
      cpuUsagePercent: 0.3,
    },
    {
      timeStamp: 5,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 6,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 7,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 8,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 9,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 10,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 11,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 12,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 13,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 14,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 15,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 16,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 17,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 18,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 19,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 20,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 21,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 22,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 23,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 24,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 25,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 26,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 27,
      cpuUsagePercent: 0.5,
    },
    {
      timeStamp: 28,
      cpuUsagePercent: 0.47,
    },
    {
      timeStamp: 29,
      cpuUsagePercent: 0.4,
    },
    {
      timeStamp: 30,
      cpuUsagePercent: 0.5,
    },
  ];

  const cardinal = curveCardinal.tension(1);

  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: -20,
          bottom: 0,
        }}
        syncId="appStats"
      >
        <CartesianGrid stroke="#cecece" strokeDasharray="1 1" />
        <XAxis dataKey="timeStamp" hide />
        <YAxis
          domain={[0, 1]}
          color="#cecece"
          stroke="#ccc"
          fontSize={8}
          tickCount={10}
          tickMargin={0}
        />

        <Tooltip />
        <Area
          type={cardinal}
          dataKey="cpuUsagePercent"
          stroke="#0284c7"
          fill="#0ea5e9"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export { ApplicationStatsCpu };
