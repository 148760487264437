import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { datadogRum } from "@datadog/browser-rum";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


datadogRum.init({
  applicationId: "ab235985-a146-45ba-b7ef-b03c89105e84",
  clientToken: "pubac265c27971a4be8dfd0160049efaeff",
  site: "datadoghq.com",
  service: "developer-app",
  env: "k-operations-prod-01",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

root.render(
  // Desabilitei o StrictMode por que por algum motivo entrava em loop
  // no processo de auth.
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
