
import { useAxios } from "@/services/axios";

const useProjectService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/accounts/project";

  const projectService = {
    getSummary: async (projectId: string) => {
      try {
        const { data } = await axiosInstance.get(
          `${baseServiceUri}/${projectId}/summary`
        );
        return data;
      } catch (error) {
        console.error("Error fetching application:", error);
        throw error;
      }
    },
  };
  return { projectService };
};

export { useProjectService };
