import { ApplicationLogs } from "@/components/application/application-logs";
import { ApplicationStatsCpu } from "@/components/application/application-stats/application-stats-cpu";
import { ApplicationStatsMemory } from "@/components/application/application-stats/application-stats-memory";
import { ApplicationStatsRequests } from "@/components/application/application-stats/application-stats-requests";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LoadingPage } from "@/pages/loading";
import {
  Application,
  useApplicationService,
} from "@/services/application-service";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

function ApplicationOverviewPage() {
  const application = useOutletContext<Application>();

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-md font-bold leading-none">
              General info
            </CardTitle>
          </CardHeader>
          <CardContent>
            Url: 
            <a
              href={application.url}
              target="_blank"
              className="underline underline-offset-4 hover:text-primary ml-2"
            >
              {application.url}
            </a>
          </CardContent>
        </Card>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-bold leading-none">
              Requests
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            <ApplicationStatsRequests />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-bold leading-none">
              CPU usage
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            <ApplicationStatsCpu />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-bold leading-none">
              Memory usage
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            <ApplicationStatsMemory />
          </CardContent>
        </Card>
      </div>
      <div>
        <Card>
          <CardHeader>Logs</CardHeader>
          <CardContent>
            <ApplicationLogs />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export { ApplicationOverviewPage };
