import { ClusterPreset, useClusterService } from "@/services/cluster-service";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { CubeIcon, GearIcon, GlobeIcon } from "@radix-ui/react-icons";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

function ClusterCreatePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [clusterPresets, setClusterPresets] = useState<ClusterPreset[]>([]);
  const { clusterService, clusterPresetService } = useClusterService();

  const navigate = useNavigate();

  const formSchema = z.object({
    displayName: z.string(),
    clusterPreset: z.string(),
    regionId: z.string(),
    defaultDomain: z.string(),
  });

  type formValues = z.infer<typeof formSchema>;

  const defaultValues: Partial<formValues> = {
    displayName: "",
    clusterPreset: "1",
    regionId: "eastus2",
    defaultDomain: "",
  };

  const form = useForm<formValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    clusterPresetService.list().then((data) => setClusterPresets(data));
  }, []);

  const onSubmit = async (data: formValues) => {
    setIsLoading(true);
    try {
      await clusterService.create({
        displayName: data.displayName,
        domain: data.defaultDomain,
        location: data.regionId,
        clusterPreset: data.clusterPreset,
      });
      navigate("/project/infrastructure");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <p className="text-lg font-bold tracking-tight">Create a Cluster</p>
      <p className="text-muted-foreground">
        Provide the initial options of your cluster
      </p>
      <Form {...form}>
        <div className="space-y-8 mt-8">
          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Cluster name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Your cluster name. Example: aks-01"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Use a good naming convention to better identify your
                      cluster.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="regionId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Region</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a valid region. Cannot be changed after creation" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="eastus2">
                          Use project default region (eastus2)
                        </SelectItem>
                        <SelectItem value="us-east-1">us-east-1</SelectItem>
                        <SelectItem value="us-east-2">us-east-2</SelectItem>
                        <SelectItem value="br-south-1">br-south-1</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="clusterPreset"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Cluster preset</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="grid grid-cols-3 gap-4"
                      >
                        {clusterPresets.map((preset) => {
                          return (
                            <FormItem key={preset.id}>
                              <Label className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary">
                                <FormControl>
                                  <RadioGroupItem
                                    value={preset.id}
                                    className="sr-only"
                                  />
                                </FormControl>
                                <CubeIcon className="mb-3 h-6 w-6" />
                                <div className="h-42">
                                  <p className="text-center text-lg font-bold">
                                    {preset.display_name}
                                  </p>
                                  <p className="text-sm text-muted-foreground font-normal text-center mt-1">
                                    {preset.description}
                                  </p>
                                </div>
                              </Label>
                            </FormItem>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="defaultDomain"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Default domain</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Specify a service domain for new apps like *.contoso.com"
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="w-full space-x-2 pt-8 flex justify-end">
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button
                  loading={isLoading}
                  onClick={form.handleSubmit(onSubmit)}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export { ClusterCreatePage };
