import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SiAmazonaws, SiGooglecloud, SiMicrosoftazure } from "react-icons/si";
import { Badge } from "@/components/ui/badge";
import { Cluster } from "@/services/cluster-service";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { CloudProviderEnum } from "@/services/enums/cloud-provider";
import {
  ClusterStatusEnum,
  clusterStatusTextMapping,
} from "@/services/enums/cluster-status";
import { ExclamationTriangleIcon, SymbolIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useClusterExtensionService } from "@/services/cluster-extension-service";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

function ClusterStatusBadge({ status }: { status: ClusterStatusEnum }) {
  return (
    // <Badge className="bg-lime-500 hover:bg-lime-700">
    <Badge>{clusterStatusTextMapping[status]}</Badge>
  );
}

function ClusterProviderAvatar({ provider }: { provider: CloudProviderEnum }) {
  switch (provider) {
    case CloudProviderEnum.AZURE_AKS:
      return <SiMicrosoftazure />;
    case CloudProviderEnum.AWS_EKS:
      return <SiAmazonaws />;
    case CloudProviderEnum.GCP_GKE:
      return <SiGooglecloud />;
    default:
      return <p>Error</p>;
  }
}

interface ClusterListItemProps {
  cluster: Cluster;
}

function ClusterListItem({ cluster }: ClusterListItemProps) {
  const {
    id,
    displayName,
    provider,
    location,
    nodeCount,
    nodeSize,
    status,
    defaultDomain,
  } = cluster;

  const { clusterExtensionService } = useClusterExtensionService();
  const [clusterWarning, setClusterWarning] = useState<{
    title: string;
    description: string;
  } | null>();

  useEffect(() => {
    if (status === 3) {
      clusterExtensionService
        .getKnativeStatus(id)
        .then((data) => {
          if (!data.validation_status_ok) {
            setClusterWarning({
              title: `Your cluster DNS validation is pending.`,
              description: `Create a wildcard record from *.${defaultDomain} to ${data.public_ip}`,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  return (
    <TableRow key={id}>
      <TableCell className="font-medium">
        <Link to={`/project/cluster/${id}/detail`} className="font-semibold">
          {displayName}
        </Link>
      </TableCell>
      <TableCell className="text-center text-sky-500 flex flex-row justify-center">
        <ClusterProviderAvatar provider={provider} />
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>
        {nodeCount}x {nodeSize}
      </TableCell>
      <TableCell>
        <ClusterStatusBadge status={status} />
        {clusterWarning && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                {/* <Button size="icon" variant="ghost"> */}
                <ExclamationTriangleIcon className="text-orange-400 ml-2" />
                {/* </Button> */}
              </TooltipTrigger>
              <TooltipContent className="w-72">
                <p className="text-center">
                  <span className="font-semibold">{clusterWarning.title} </span>
                  <br />
                  <span className="text-muted-foreground">
                    {clusterWarning.description}
                  </span>
                  <br />
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </TableCell>
    </TableRow>
  );
}

interface ClusterListProps {
  items: Cluster[];
  onRefresh: () => void;
}
interface ClusterListCommandsProps {
  onRefresh: () => void;
}
function ClusterListCommands({ onRefresh }: ClusterListCommandsProps) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between">
      <div></div>
      <div className="space-x-2">
        <Button size="icon" variant="ghost">
          <SymbolIcon onClick={onRefresh} />
        </Button>
        <Button
          variant="outline"
          onClick={() => navigate("/project/cluster-create")}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

function ClusterList({ items, onRefresh }: ClusterListProps) {
  console.log(items);
  const navigate = useNavigate();

  if (!items || items.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center">
        <p className="text-muted-foreground text-lg my-2 text-center">
          Looks like you don't have any cluster in your project...
        </p>
        <div className="space-x-2">
          <Button variant="outline">Import existing cluster</Button>
          <Button
            variant="default"
            onClick={() => navigate("/project/cluster-create")}
          >
            Create cluster
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="space-y-4">
      <ClusterListCommands onRefresh={onRefresh} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[160px]">Cluster name</TableHead>
              <TableHead className="text-center">Provider</TableHead>
              <TableHead>Region</TableHead>
              <TableHead>Size</TableHead>
              <TableHead>Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((item) => (
              <ClusterListItem key={item.id} cluster={item} />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export { ClusterList };
