import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { ReactNode, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { BiLogoGoogleCloud, BiLogoAws } from "react-icons/bi";
import { SiMicrosoftazure } from "react-icons/si";
import { CloudAccountCreateAzure } from "./cloud-account-create/cloud-account-create-azure";

interface ProviderOptionProps {
  title: string;
  description: string;
  icon: ReactNode;
  id: string;
  value: string;
  disabled: boolean;
}

function ProviderOption({
  title,
  description,
  icon,
  id,
  value,
  disabled,
}: ProviderOptionProps) {
  return (
    <Label
      htmlFor={id}
      className="flex flex-col w-full items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary"
    >
      <RadioGroupItem
        id={id}
        value={value}
        className="sr-only"
        disabled={disabled}
      />
      {icon}
      <div>
        <p
          className={
            disabled ? "text-center text-muted-foreground" : "text-center"
          }
        >
          {title}
        </p>
        <p className="text-sm text-muted-foreground font-normal text-center mt-1">
          {description}
        </p>
      </div>
    </Label>
  );
}

interface CloudAccountCreateProps {
  onClose: () => void;
}

function CloudAccountCreate({ onClose }: CloudAccountCreateProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedProvider, setSelectedProvider] = useState(null);

  const providerOptions: ProviderOptionProps[] = [
    {
      id: "azure",
      value: "azure",
      title: "Azure",
      description: "AKS",
      icon: <SiMicrosoftazure className="mb-3 h-6 w-6" />,
      disabled: false,
    },
    {
      id: "aws",
      value: "aws",
      title: "AWS",
      description: "EKS",
      icon: <BiLogoAws className="mb-3 h-6 w-6 text-muted-foreground" />,
      disabled: true,
    },
    {
      id: "google_cloud",
      value: "google_cloud",
      title: "GCP",
      description: "GKE",
      icon: (
        <BiLogoGoogleCloud className="mb-3 h-6 w-6 text-muted-foreground" />
      ),
      disabled: true,
    },
  ];

  const handleProviderChange = (value: any) => {
    setSelectedProvider(value);
  };

  const handleOnClose = () => {
    onClose();
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="default">Connect Cloud Provider</Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Connect your cloud provider</SheetTitle>
          <SheetDescription>
            Pick a cloud provider to use your project and provide its
            credentials
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-row items-center justify-center space-x-2 py-4">
          <RadioGroup
            onValueChange={(value) => handleProviderChange(value)}
            className="flex flex-row w-full"
          >
            {providerOptions.map((option) => (
              <ProviderOption key={option.id} {...option} />
            ))}
          </RadioGroup>
        </div>

        <div className="h-full p-2">
          {selectedProvider === "azure" && (
            <CloudAccountCreateAzure onClose={() => handleOnClose()} />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}

export { CloudAccountCreate };
