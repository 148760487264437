import { Link } from "react-router-dom";

function SignupConfirmationPage() {
  return (
    <div className="flex flex-row h-screen w-screen items-center justify-center">
      <div className="flex flex-col">
        <div>
          <p className="text-lg font-medium text-center">
            Success!
          </p>
          <p className="text-muted-foreground">
            Your account has been created. Please check your inbox for
            activation instructions.
          </p>
        </div>
        <div className="text-center pt-4">
          <Link to="/" className="">Proceed to login</Link>
        </div>
      </div>
    </div>
  );
}

export { SignupConfirmationPage };
