import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Cluster, useClusterService } from "@/services/cluster-service";
import { Loader2 } from "lucide-react";
import { BaseSyntheticEvent, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ClusterDetailPage() {
  const [defaultDomain, setDefaultDomain] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { id } = useParams();

  const { clusterService } = useClusterService();

  const navigate = useNavigate();

  useEffect(() => {
    clusterService.get(id as string).then((data) => {
      const { defaultDomain } = data as Cluster;

      if (defaultDomain) {
        setDefaultDomain(defaultDomain);
      }
    });
  }, [id]);

  const handleDefaultDomainChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setDefaultDomain(event.currentTarget.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await clusterService.updateDefaultDomain(id as string, defaultDomain);
    navigate("/project/infrastructure");
  };

  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Label htmlFor="defaultDomain">Default domain</Label>
      <Input
        id="defaultDomain"
        value={defaultDomain}
        onChange={handleDefaultDomainChange}
      />

      <div>
        <Button loading={isSubmitting} onClick={() => handleSubmit()}>
          Save changes
        </Button>
      </div>
    </div>
  );
}

export { ClusterDetailPage };
