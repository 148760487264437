enum CloudProviderEnum {
  AZURE_AKS = 1,
  AWS_EKS = 2,
  GCP_GKE = 3,
}

export type CloudProviderTextMapping = {
  [key in CloudProviderEnum]: string;
};

const cloudProviderTextMapping: CloudProviderTextMapping = {
  [CloudProviderEnum.AZURE_AKS]: "Azure AKS",
  [CloudProviderEnum.AWS_EKS]: "AWS EKS",
  [CloudProviderEnum.GCP_GKE]: "GCP GKE",
};

export { CloudProviderEnum, cloudProviderTextMapping };
