enum ClusterStatusEnum {
  NEW = 1,
  PROVISIONING = 2,
  RUNNING = 3,
  UPDATING = 4,
  FAILED = 5,
  DELETED = 6,
}

export type ClusterStatusTextMapping = {
  [key in ClusterStatusEnum]: string;
};

const clusterStatusTextMapping: ClusterStatusTextMapping = {
  [ClusterStatusEnum.NEW]: "New",
  [ClusterStatusEnum.PROVISIONING]: "Provisioning",
  [ClusterStatusEnum.RUNNING]: "Running",
  [ClusterStatusEnum.UPDATING]: "Updating",
  [ClusterStatusEnum.FAILED]: "Failed",
  [ClusterStatusEnum.DELETED]: "Deleted",
};

export { ClusterStatusEnum, clusterStatusTextMapping };
