import { AppsNav } from "@/components/layout/apps-nav";
import { PageHeader } from "@/components/layout/page-header";
import { UserNav } from "@/components/layout/user-nav";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingPage } from "../loading";
import { OrganizationCreateForm } from "@/components/organization/organization-create-form";
import {
  OrganizationInvite,
  useOrganizationInviteService,
} from "@/services/organization-invite-service";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";

function AccountSetupPage() {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const [hasPendingInvitation, setHasPendingInvitation] = useState(false);
  const [organizationInvite, setOrganizationInvite] =
    useState<OrganizationInvite>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organizationInviteService } = useOrganizationInviteService();
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn && initialized) {
      keycloak.login();
    }
    if (isLoggedIn && initialized) {
      organizationInviteService
        .get()
        .then((data) => {
          console.log(data);
          if (data) {
            setOrganizationInvite(data);
            setHasPendingInvitation(true);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setHasPendingInvitation(false);
          setIsLoading(false);
        });
    }
  }, [keycloak, initialized, isLoggedIn]);

  const handleAccept = async () => {
    setIsSubmitting(true);
    await organizationInviteService.accept(organizationInvite?.id as string);
    navigate("/")
  };
  if (isLoading) return <LoadingPage />;

  return isLoggedIn ? (
    <div className="flex-col md:flex">
      <div className="flex h-16 items-center px-2 md:px-8">
        <nav className="flex items-center space-x-4 lg:space-x-6">
          <Link to="/">
            <div className="flex flex-row items-center justify-center space-x-1">
              <strong>K-Operations</strong>
            </div>
          </Link>
        </nav>
        <div className="ml-auto flex items-center space-x-4">
          <UserNav />
          <AppsNav />
        </div>
      </div>
      <div className="flex-1 space-y-4 p-8 pt-6">
        {hasPendingInvitation ? (
          <Dialog
            open={true}
            onOpenChange={() => setHasPendingInvitation(false)}
          >
            {/* <DialogTrigger>Open</DialogTrigger> */}
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="text-center">
                  {organizationInvite?.organization.displayName} invited you to
                  join.
                </DialogTitle>
                <DialogDescription>
                  <div className="text-center">
                    {/* <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="text-sky-500 ml-2">
                          <InfoCircledIcon />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            You can create your company later and can belong to
                            many company and projects.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider> */}
                    <p>
                      Do you want to accept this invitation instead of creating
                      your own company right now?
                    </p>
                  </div>

                  <div className="flex flex-row justify-center space-x-2 mt-4">
                    <Button
                      variant="outline"
                      onClick={() => setHasPendingInvitation(false)}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => handleAccept()}
                      loading={isSubmitting}
                    >
                      Accept
                    </Button>
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        ) : (
          <>
            <PageHeader
              title="Let's setup your account!"
              // subtitle="Please provide the following information to better customize your experience"
            />
            <OrganizationCreateForm />
          </>
        )}
      </div>
    </div>
  ) : (
    <p>Access denied. Redirecting to login page.</p>
  );
}

export { AccountSetupPage };
