import { useAxios } from "@/services/axios";

interface CreateCloudAccountRequest {
  projectId: string;
  organizationId: string;
  clientId: string;
  clientSecret: string;
  tenantId: string;
}

interface KnativeExtensionStatus {
  public_ip: string;
  validation_status_ok: boolean;
}

const useClusterExtensionService = () => {
  const axiosInstance = useAxios();
  const baseServiceUri = "/applications/cluster-extension";

  const clusterExtensionService = {
    getKnativeStatus: async (clusterId: string) => {
      const uri = `${baseServiceUri}/knative-serving?cluster_id=${clusterId}`;
      const { data } = await axiosInstance.get<KnativeExtensionStatus>(uri);
      return data;
    },
  };
  return { clusterExtensionService };
};

export { useClusterExtensionService };
