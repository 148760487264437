import { createContext, useContext } from "react";

export type Project = {
  id: string | null;
  organizationId: string | null;
  displayName: string | null;
  // organizationId: string | null;

};
export type CurrentProjectType = {
  currentProject: Project | null;
  updateCurrentProject: (project: Project) => void;
};

const defaultValues = {
  currentProject: null,
  updateCurrentProject: () => null,
};

const CurrentProjectContext = createContext<CurrentProjectType>(defaultValues);

const useCurrentProject = () => {
  const currentUserContext = useContext(CurrentProjectContext);

  return currentUserContext;
};

export { CurrentProjectContext, useCurrentProject };
