import { PageHeader } from "@/components/layout/page-header";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { CubeIcon, GearIcon, GlobeIcon } from "@radix-ui/react-icons";
import { ContainerRegistryPicker } from "@/components/container-registry/container-registry-picker";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useApplicationService } from "@/services/application-service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cluster, useClusterService } from "@/services/cluster-service";
import cluster from "cluster";

function ApplicationCreatePage() {
  const { applicationService } = useApplicationService();
  const { clusterService } = useClusterService();

  const [clusterList, setClusterList] = useState<Cluster[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const formSchema = z.object({
    displayName: z.string().min(2, {
      message: "displayname must be at least 2 characters.",
    }),
    applicationType: z.string(),
    deploymentType: z.string(),
    containerImage: z.string(),
    regionId: z.string(),
    clusterId: z.string(),
  });

  type formValues = z.infer<typeof formSchema>;

  const defaultValues: Partial<formValues> = {
    displayName: "example-service",
    applicationType: "web-api",
    deploymentType: "container",
    containerImage: "kuracloudpublic/sample-app:0.3.1",
    regionId: "eastus2",
    clusterId: "default",
  };

  const form = useForm<formValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const onSubmit = async (data: formValues) => {
    setIsSubmitting(true);
    await applicationService.create({
      displayName: data.displayName,
      regionId: data.regionId,
      type: data.deploymentType,
      initialContainerImage: data.containerImage,
      repoUrl: "https://repo",
      shortName: data.displayName,
    });

    navigate("/application");
  };
  

  const setContainerImage = (displayName: string) => {
    form.setValue("containerImage", displayName);
  };

  useEffect(() => {
    clusterService.list().then(data => {setClusterList(data)})
  },[])

  return (
    <>
      <PageHeader title="Applications" subtitle="Manage your applications" />
      <div className="w-[800px] grid gap-6">
        <Form {...form}>
          <div className="space-y-8">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="displayName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Application name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Your application name. I.e. purchase-order-service"
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>
                        Use a good naming convention to better identify your
                        application.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="regionId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Region</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a valid region. Cannot be changed after creation" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="eastus2">
                            Default region
                          </SelectItem>
                          <SelectItem value="us-east-1">us-east-1</SelectItem>
                          <SelectItem value="us-east-2">us-east-2</SelectItem>
                          <SelectItem value="br-south-1">br-south-1</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="clusterId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cluster</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a valid region. Cannot be changed after creation" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="default">Any available</SelectItem>
                          {clusterList?.map(item => <SelectItem value={item.id}>{item.displayName}</SelectItem> )}
                        </SelectContent>
                      </Select>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <FormField
              control={form.control}
              name="applicationType"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Application type</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="grid grid-cols-3 gap-4"
                    >
                      <FormItem>
                        <Label className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary">
                          <FormControl>
                            <RadioGroupItem
                              value="static-app"
                              className="sr-only"
                            />
                          </FormControl>
                          <CubeIcon className="mb-3 h-6 w-6" />
                          <div className="h-42">
                            <p className="text-center">Static Web App (SPA)</p>
                            <p className="text-sm text-muted-foreground font-normal text-center mt-1">
                              An application that always responds for the same
                              HTTP content.
                            </p>
                          </div>
                        </Label>
                      </FormItem>
                      <FormItem>
                        <Label
                          htmlFor="web-api"
                          className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary"
                        >
                          <FormControl id="web-api">
                            <RadioGroupItem
                              id="web-api"
                              value="web-api"
                              className="sr-only"
                            />
                          </FormControl>
                          <GlobeIcon className="mb-3 h-6 w-6" />
                          <div className="h-42">
                            <p className="text-center">Web API</p>
                            <p className="text-sm text-muted-foreground font-normal text-center mt-1">
                              An application that runs and processes HTTP
                              requests in server-side.
                            </p>
                          </div>
                        </Label>
                      </FormItem>
                      <FormItem>
                        <Label
                          htmlFor="worker"
                          className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary"
                        >
                          <FormControl>
                            <RadioGroupItem
                              id="worker"
                              value="worker"
                              className="sr-only"
                            />
                          </FormControl>
                          <GearIcon className="mb-3 h-6 w-6" />
                          <div className="h-42">
                            <p className="text-center">Worker (headless)</p>
                            <p className="text-sm text-muted-foreground font-normal text-center mt-1">
                              A daemon application that is always running. It
                              doesn't expose any port.
                            </p>
                          </div>
                        </Label>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="containerImage"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Container Image</FormLabel>
                  <div className="flex w-full max-w-sm items-center space-x-2">
                    <FormControl>
                      <Input
                        placeholder="Your application name. I.e. purchase-order-service"
                        {...field}
                      />
                    </FormControl>
                    <ContainerRegistryPicker onSelected={setContainerImage} />
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              onClick={form.handleSubmit(onSubmit)}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export { ApplicationCreatePage };
