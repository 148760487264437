import { Button } from "@/components/ui/button";
import Grid from "@/components/ui/grid";
import {
  ApplicationStatus,
  ApplicationTile,
} from "@/components/application/application-tile";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@/components/layout/page-header";
import { useEffect, useState } from "react";
import {
  Application,
  useApplicationService,
} from "@/services/application-service";

function ApplicationIndexPage() {
  const { applicationService } = useApplicationService();

  const [applicationList, setApplicationList] = useState<Application[]>([]);

  useEffect(() => {
    const fetchData = async () => await applicationService.list();

    fetchData().then((data) => {
      console.log(data);
      setApplicationList(data as Application[]);
    });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Applications">
        <Button onClick={() => navigate("/application/create")}>
          Create App
        </Button>
      </PageHeader>

      <Grid>
        {applicationList && applicationList.length > 0 ? (
          applicationList.map((application) => (
            <ApplicationTile
              application={application}
              key={application.id}
              status={ApplicationStatus.running}
            />
          ))
        ) : (
          <p className="text-muted-foreground">No applications to show</p>
        )}
      </Grid>
    </>
  );
}

export { ApplicationIndexPage };
