import { toast } from "@/components/ui/use-toast";
import { useAxios } from "./axios";

interface AccountSetupRequest {
  organization: {
    displayName: string;
    legalName: string;
    taxId: string;
    preferredRegion: string;
    businessIndustry: string;
  };
}

export const useAccountSetupService = () => {
  const axiosInstance = useAxios();

  const baseServiceUri = "/accounts/account-setup/post-social-provider";
  const accountSetupService = {
    create: async (request: AccountSetupRequest) => {
      try {
        const response = await axiosInstance.post(baseServiceUri, request);

        toast({
          title: "Success!",
          description: "Your company and initial project have been created.",
        });
        return response.data;
      } catch (error) {
        toast({
          title: "Oops!",
          variant: "destructive",
          description: "We couldn't complete your initial setup.",
        });
        throw error;
      }
    },
  };

  return { accountSetupService };
};
