const industryOptions = [
  { value: "1", label: "Finance" },
  { value: "2", label: "Technology" },
  { value: "3", label: "Healthcare" },
  { value: "4", label: "Retail" },
  { value: "5", label: "Manufacturing" },
  { value: "6", label: "Hospitality" },
  { value: "7", label: "Real Estate" },
  { value: "8", label: "Automotive" },
  { value: "9", label: "Telecommunications" },
  { value: "10", label: "Energy" },
  { value: "11", label: "Media and Entertainment" },
  { value: "12", label: "E-commerce" },
  { value: "13", label: "Consulting" },
  { value: "14", label: "Education" },
  { value: "15", label: "Transportation" },
  { value: "16", label: "Food and Beverage" },
  { value: "17", label: "Insurance" },
  { value: "18", label: "Pharmaceuticals" },
  { value: "19", label: "Agriculture" },
  { value: "20", label: "Construction" },
  // Add more industry options as needed
];

export { industryOptions };
